<template>
  <div class="figures">
    <ComponentDetailHeader :text="getTitle" />

    <div class="figures-content">
      <ImageWithLightbox
        v-for="(figure, idx) in figures"
        :cytk-access-token="cytkAccessToken"
        :key="figure.url"
        :img-alt="figure.label"
        :other-images="figures"
        :img-url="figure.url"
        :index="idx"
        :title="figure.label"
      />
    </div>
  </div>
</template>

<script>
import ComponentDetailHeader from '../components/ComponentDetailHeader.vue';
import ImageWithLightbox from '../components/ImageWithLightbox.vue';

export default {
  name: 'Figures',
  components: { ComponentDetailHeader, ImageWithLightbox },
  props: {
    title: {
      type: String,
      default: 'Figures',
    },
    figures: {
      type: Array,
      default: () => [],
    },
    cytkAccessToken: String,
  },
  data() {
    return {
      activeNumber: null,
    };
  },
  computed: {
    getTitle() {
      if (this.figures && this.figures[0] && this.figures[0].title) {
        return this.figures[0].title;
      }
      return this.title;
    },
  },
  watch: {
    activeNumber() {
      if (this.activeNumber) {
        this.scrollTo(this.activeNumber);
      }
    },
  },
  mounted() {
    this.showMotorLogo();
    this.$native.on('figures:active', this.setActiveFromEvent);
  },
  beforeDestroy() {
    this.hideMotorLogo();
    this.$native.off('figures:active', this.setActiveFromEvent);

    if (this.activeNumber) {
      this.$native.send({ event: 'figures:inactive' });
    }
  },
  methods: {
    isActive(index) {
      return this.activeNumber && this.activeNumber === (index + 1);
    },

    scrollTo(smooth = true) {
      const figure = this.$el.querySelector(`.figure-${this.activeNumber}`);
      if (figure) {
        figure.scrollIntoView({
          behavior: smooth ? 'smooth' : 'instant',
          block: 'start',
          inline: 'nearest',
        });
      }
    },

    setActiveFromEvent(event) {
      this.activeNumber = parseInt(event.number, 10) || null;
    },
  },
};
</script>

<style lang="scss">
@import "../mixins";

.figures {
  padding-bottom: 60px;
}

.header {
  @include header-small();
}

.figures-title {
  margin: 0;
  font-size: 1rem;
}

.figures-content {
  flex: 1;
  height: 100%;
  overflow-y: auto;
  padding-left: 8px;
  padding-right: 8px;
}
</style>

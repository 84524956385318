<template>
  <div class="container">
    <ComponentDetailHeader :text="dtcPinpointTest.title" />

    <div
      v-for="(test, index) in dtcPinpointTest.media"
      :key="test.url"
      class="image-container"
    >
      <ImageWithLightbox
        :index="index"
        :cytk-access-token="dtcPinpointTest.cytkAccessToken"
        :other-images="dtcPinpointTest.media"
        :title="test.label"
        :img-alt="test.title"
        :img-url="test.url"
      />
    </div>
    <template v-if="supportingTests.length > 0">
      <PinPointTestDrawer
        :tests="supportingTests"
        :v2="v2"
      />
    </template>
  </div>
</template>

<script>
import ImageWithLightbox from '../components/ImageWithLightbox.vue';
import ComponentDetailHeader from '../components/ComponentDetailHeader.vue';
import PinPointTestDrawer from '../components/PinPointTestDrawer.vue';

export default {
  name: 'DTCPinpointTest',
  components: { ComponentDetailHeader, ImageWithLightbox, PinPointTestDrawer },
  props: {
    dtcPinpointTest: Object,
  },
  data() {
    return {
      supportingTests: [],
      v2: false,
    };
  },
  mounted() {
    const tests = JSON.parse(localStorage.getItem('dtcSupportingTests'));
    if (tests && tests.some((test) => test.value === this.dtcPinpointTest.id)) {
      this.supportingTests = tests;
    }
    const v2 = localStorage.getItem('v2');
    this.v2 = !!v2;

    this.showMotorLogo();
  },
};
</script>

<style>
.container {
  display: flex;
  flex-direction: column;
}

.image-container {
  margin-top: 28px;
  padding: 8px;
}

</style>

<template>
  <div class="summary-container-v2">
    <div
      :class="`summary-section-title ${this.cytkContentDataType}`"
      :key="this.title + 'summary title'"
    >
      {{ this.title }}
    </div>

    <template v-for="section in this.sections">
      <template v-if="section.section_type === 'section'">
        <div
          v-if="section.title && title !== section.title"
          class="summary-section-title"
          :key="title + 'section' + section.title"
        >
          <template
            v-for="(t, idx) in section.title.split(';')"
          >
            <div :key="t"
                 :class="`summary-section-title ${idx === 0 ? 'first' : 'sub'}`"
            >
              {{ t }}
            </div>
          </template>
        </div>
        <template v-if="section.content.length === 0 && (!media || media.length === 0)">
          <div
            class="summary-no-content"
            :key="section.title"
          >
            <div class="summary-no-content-message">
              There's no content available
            </div>
          </div>
        </template>
        <SummarySectionV2
          v-else
          :key="title + '-' + section.title + 'content'"
          :sections="section.content"
        />
      </template>
    </template>

    <FiguresDrawer
      v-if="cytkContentDataType === 'parts_and_labor'"
      :cytk-access-token="cytkAccessToken"
      ref="figures"
      :figures="media"
    />

    <div
      v-else
      class="summary-image-container"
    >
      <ImageWithLightbox
        v-for="(img, idx) in media"
        :cytk-access-token="cytkAccessToken"
        :key="img.url"
        :img-alt="img.label"
        :other-images="media"
        :img-url="img.url"
        :index="idx"
        :title="img.label"
      />
    </div>
  </div>
</template>

<script>

import SummarySectionV2 from '../components/SummarySectionV2.vue';
import ImageWithLightbox from '../components/ImageWithLightbox.vue';
import FiguresDrawer from '../components/FiguresDrawer.vue';

export default {
  name: 'SummaryV2',
  components: {
    FiguresDrawer, ImageWithLightbox, SummarySectionV2,
  },
  props: {
    title: String,
    sections: Array,
    media: Array,
    cytkContentDataType: String,
    cytkAccessToken: String,
  },
  mounted() {
    this.showMotorLogo();
  },
};
</script>

<style lang="scss" scoped>
@import "../mixins";

.summary-no-content {
  background-color: $surface-light;
  width: 100%;
  border-radius: 5px;
}

.summary-no-content-message {
  padding: 16px;
  text-align: center;
}

.summary-container-v2 {
  display: flex;
  flex-direction: column;
  padding: 0 0 60px 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.summary-image-container {
  margin-top: 30px;
}

.summary-section-title {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 16px;
  margin-top: 16px;
  &.quick_view {
    padding: 1em 0;
    width: 100%;
    margin-top: 0;
    background: $surface-light;
    font-size: 20px;
  }
  &.first {
    margin: 2px 0;
  }
  &.sub {
    text-transform: none;
    margin: 0;
  }
}

</style>

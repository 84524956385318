<template>
  <div class="main-container">
    <ComponentDetailHeader
      v-if="!pdf"
      :text="dtc.title"
    />

    <div class="dtc-container">
      <PDFDocument
        v-if="pdf"
        :key="pdf.name"
        :pdf="pdf"
        :title="dtc.title"
      />

      <ImageWithLightbox
        v-else
        v-for="(media, index) in dtc.media.filter(m => m.contentType !== 'application/pdf')"
        :key="media.url"
        :cytk-access-token="dtc.cytkAccessToken"
        :index="index"
        :other-images="dtc.media"
        :img-url="media.url"
        :img-alt="media.label"
        :title="`${media.label}`"
      />
    </div>
    <PinPointTestDrawer
      v-if="dtc.dtcSupportingTests && dtc.dtcSupportingTests.length > 0"
      :v2="this.v2"
      :tests="dtc.dtcSupportingTests"
    />
  </div>
</template>

<script>
import ImageWithLightbox from '../components/ImageWithLightbox.vue';
import PinPointTestDrawer from '../components/PinPointTestDrawer.vue';
import ComponentDetailHeader from '../components/ComponentDetailHeader.vue';
import PDFDocument from '../components/PDFDocument.vue';

export default {
  name: 'DTC',
  components: {
    PinPointTestDrawer,
    ImageWithLightbox,
    ComponentDetailHeader,
    PDFDocument,
  },
  props: {
    dtc: Object,
    v2: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  computed: {
    pdf() {
      const pdfs = this.dtc.media
        && (this.dtc.media.filter((media) => media.contentType === 'application/pdf'));
      return pdfs?.length > 0 ? pdfs[0] : false;
    },
  },
  mounted() {
    localStorage.setItem('dtcSupportingTests', JSON.stringify(this.dtc.dtcSupportingTests));
    localStorage.setItem('v2', this.v2);
  },
};
</script>

<style scoped>
body {
  overflow: hidden;
}

.dtc-container {
  padding: 8px 8px 60px 8px;
}

#app {
  position: relative;
}

.detail-header {
  min-height: inherit;
}
</style>

<template>
  <div>
    <span
      class="close"
      aria-hidden="true"
      @click.prevent="onClose"
    />
    <div
      class="image-overlay-container"
    >
      <ZoomImage
        :cytk-access-token="cytkAccessToken"
        :img-alt="currentImage.alt"
        :img-url="currentImage.url"
        :legend="currentImage.legend"
      />
    </div>
  </div>
</template>

<script>
import ZoomImage from './ZoomImage.vue';

export default {
  name: 'ImageOverlay',
  components: { ZoomImage },
  props: {
    currentImage: {
      type: Object,
      required: true,
    },
    cytkAccessToken: {
      type: String,
      required: true,
    },
  },
  methods: {
    onClose() {
      this.$emit('closing');
    },
  },
};
</script>

<style lang="scss">
.image-overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(#000, 0.75);
  z-index: 998;
  padding-left: 8px;
  padding-right: 8px;
  box-sizing: border-box;
}

.close {
  background-image: url("../assets/cross.png");
  position: fixed;
  z-index: 999;
  right: 0;
  top: 0;
  height: 24px;
  width: 24px;
  font-size: 28px;
  color: $text-tertiary;
  margin: 5% 2% auto auto;
}

</style>

<template>
  <div>
    <ComponentDetailHeader :text="title" />
    <div class="container">
      <div class="imagesContainer">
        <template v-for="(wire, index) in wires.media">
          <ImageWithLightbox
            :key="wire.url"
            :cytk-access-token="wires.cytkAccessToken"
            :index="index"
            :other-images="wires.media"
            :img-url="wire.url"
            :img-alt="wire.label"
            :title="wire.label"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>

import ComponentDetailHeader from '../components/ComponentDetailHeader.vue';
import ImageWithLightbox from '../components/ImageWithLightbox.vue';

export default {
  name: 'Wires',
  components: { ComponentDetailHeader, ImageWithLightbox },
  props: {
    wires: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.showMotorLogo();
  },
  computed: {
    title() {
      return this.wires.title || 'WIRES';
    },
  },
};
</script>

<style lang="scss">
.container {
  background-color: $surface;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-height: 100%;
  padding: 14px;
}

.title {
  text-align: center;
  color: $text-secondary;
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
}

.imagesContainer {
  margin-top: 36px;
}

</style>
